// Put your main states here
export default {
    isLoading: false,
    loaderMessage: 'Please wait',
    fullPage: true,
    color: 'red',
    loader: 'dots',
    opacity: 0.8,
    isAuth: false,
    DeletRole: true,
    locale:'',
    languageList:[],
    countries: [],
    userBrief: {
        login_date: null,
        login_time: null,
        name: null,
        role: null,
        exp: null,
        location: null,
        avail: null,
        industry: null,
        membership_plan: null,
        membership_expiry: null
    },
    alerts: {
        default: false,
        success: false,
        warning: false,
        info: false,
        message: null
    },
    membershipPlanData:'',
    membershipPlan:'',
    // headers: {
    //     mainHeaderLinks: [

    //         {name: 'head-s', link: 'advanced-search/job', class: 'menu_active hover:bg-red-500 py-1 w-40', show: true},
    //         {name: 'head-e', link: 'employer', class: 'menu_active hover:bg-red-500 py-1 w-40', show: true},
    //         {name: 'head-js', link: 'job-seeker', class: 'menu_active hover:bg-red-500 py-1 w-40', show: true},
    //         {name: 'TITP', link: 'titp?filter=job&titp=1', class: 'menu_active hover:bg-red-500 py-1 w-40', show: true},
    //         {name: 'head-ot', link: 'outsourcing-tender', class: 'menu_active hover:bg-red-500 py-1 w-40', show: true},
    //         {name: 'head-jf', link: 'job-fair', class: 'menu_active hover:bg-red-500 py-1 w-40', show: true},
    //         {name: 'head-a', link: 'advertisements', class: 'menu_active hover:bg-red-500 py-1 w-40', show: true},
    //         {name: 'head-rs', link: 'relocation-service', class: 'menu_active hover:bg-red-500 py-1 w-40', show: true},
    //         {name: 'head-d', link: 'dashboard-home', class: 'menu_active hover:bg-red-500 py-1 w-40', show: true},
    //         {name: 'head-si', link: 'sign-in', class: 'menu_active_sign text-red-500 hover:text-white hover:bg-red-900 font-semibold py-1 w-40', show: true},
            
    //     ]
    // },
    showSidebar: false,
    myprofile: {
        firstname: "",
        lastname: "",
        designation: "",
        img: "/images/vectorprofile.webp",
        role:"",
        email:'',
        company_logo:'/images/smartrecruit.webp',
    },
    sidebar: [
        {
            slug: 'dashboard',
            name: 'head-d', 
            link: 'Dashboard', 
            svg: `
                <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.5" stroke="#fff"/><circle cx="25" cy="25" r="20.75" fill="#EAEAEA" stroke="#fff" stroke-width=".5"/><path d="M20.6 28.348a1.025 1.025 0 1 0 0 2.05 1.025 1.025 0 0 0 0-2.05ZM28.794 28.348a1.025 1.025 0 1 0 .001 2.05 1.025 1.025 0 0 0 0-2.05Z" fill="#444546"/><path d="M24.697 14a12.259 12.259 0 0 0-8.694 3.6l-.003.002-.002.003a12.26 12.26 0 0 0-3.599 8.682V26.308a12.249 12.249 0 0 0 2.811 7.817c.195.236.484.372.79.372h17.393c.306 0 .595-.136.79-.372a12.25 12.25 0 0 0 2.812-7.827C36.995 19.506 31.49 14 24.697 14Zm8.2 18.447h-16.4a10.183 10.183 0 0 1-1.998-5.124h3.024a1.025 1.025 0 0 0 0-2.05h-3.024a10.2 10.2 0 0 1 2.263-5.46l.688.688a1.025 1.025 0 0 0 1.449-1.45l-.688-.688a10.2 10.2 0 0 1 5.46-2.262v3.023a1.025 1.025 0 1 0 2.05 0v-3.023a10.2 10.2 0 0 1 5.461 2.262l-.688.688a1.025 1.025 0 1 0 1.45 1.45l.688-.689a10.2 10.2 0 0 1 2.263 5.461H31.87a1.025 1.025 0 1 0 0 2.05h3.024a10.184 10.184 0 0 1-1.999 5.124Z" fill="#444546"/><path d="m24.871 28.348 2.898-5.797-5.796 2.9 2.898 2.897Z" fill="#444546"/></svg>
            `, 
            class: 'side_active my-auto px-2 py-3 w-full rounded bg-menuBG text-sideBarBg hover:text-white hover:bg-red-700', 
            show: true
        },
        {
            slug: 'roles-and-permission',
            name: 'sd-menu-txt4', 
            link: 'role-and-permission', 
            svg: `<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.5" stroke="white"/><circle cx="25" cy="25" r="20.75" fill="#EAEAEA" stroke="white" stroke-width="0.5"/><path d="M15 17.15C15 15.72 15.72 15 17.15 15H21.45C22.88 15 23.6 15.72 23.6 17.15V19" stroke="#444546" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M19.7 30.9991H17.15C15.72 30.9991 15 30.2791 15 28.8491V21.0391" stroke="#444546" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M30.3692 21.42V32.58C30.3692 34.19 29.5692 35 27.9592 35H22.1192C20.5092 35 19.6992 34.19 19.6992 32.58V21.42C19.6992 19.81 20.5092 19 22.1192 19H27.9592C29.5692 19 30.3692 19.81 30.3692 21.42Z" stroke="#444546" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M26.3984 19V17.15C26.3984 15.72 27.1184 15 28.5484 15H32.8484C34.2784 15 34.9984 15.72 34.9984 17.15V28.85C34.9984 30.28 34.2784 31 32.8484 31H30.3684" stroke="#444546" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M23 24H27" stroke="#444546" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M23 27H27" stroke="#444546" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M25 35V32" stroke="#444546" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>`
            , 
            class: 'side_active my-auto px-2 py-3 w-full rounded bg-menuBG text-sideBarBg hover:text-white hover:bg-red-700', 
            show: true
        },
        {
            slug: 'agreements',
            name: 'ea-col1-txt5', 
            link: 'agreements', 
            svg: `
                <svg width="51" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25.5" cy="25" r="24.5" stroke="#fff"/><circle cx="25.5" cy="25" r="20.75" fill="#EAEAEA" stroke="#fff" stroke-width=".5"/><path d="M33 15H19a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V16a1 1 0 0 0-1-1Z" stroke="#444546" stroke-linecap="round" stroke-linejoin="round"/><path d="M22 15h4.5v8l-2.25-2L22 23v-8Z" stroke="#444546" stroke-linecap="round" stroke-linejoin="round"/><path d="M22 27h5M22 30h8" stroke="#444546" stroke-linecap="round"/></svg>
            `, 
            class: 'side_active my-auto px-2 py-3.5 w-full rounded bg-menuBG text-sideBarBg hover:text-white hover:bg-red-700', 
            show: true
        },
        {
            slug: 'my-tasks',
            name: 'ed-menu-txt1', 
            link: 'my-tasks', 
            svg: `
                  <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.5" stroke="#fff"/><circle cx="25" cy="25" r="20.75" fill="#EAEAEA" stroke="#fff" stroke-width=".5"/><path d="M18.7 34h11.9c.938 0 1.7-.762 1.7-1.7V19.55c0-.938-.762-1.7-1.7-1.7h-1.7a.85.85 0 0 0-.85-.85h-6.8a.85.85 0 0 0-.85.85h-1.7c-.938 0-1.7.762-1.7 1.7V32.3c0 .938.762 1.7 1.7 1.7Zm0-14.45h1.7v1.7h8.5v-1.7h1.7V32.3H18.7V19.55Z" fill="#444546"/><path d="m23.8 26.85-1.524-1.525-1.202 1.202 2.726 2.726 4.426-4.426-1.202-1.202-3.224 3.224Z" fill="#444546"/></svg>
            `, 
            class: 'side_active my-auto px-2 py-3 w-full rounded bg-menuBG text-sideBarBg hover:text-white hover:bg-red-700', 
            show: true
        },
        {
            slug: 'resume',
            name: 'jd-txt2', 
            link: 'job-seeker-resume', 
            svg: `
                   <svg width="51" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25.25" cy="25" r="24.5" stroke="#fff"/><circle cx="25.25" cy="25" r="20.75" fill="#EAEAEA" stroke="#fff" stroke-width=".5"/><path d="M17.817 16.567h11.05v1.983h.566v-2.267c0-.17-.113-.283-.283-.283H17.533c-.17 0-.283.113-.283.283v16.434c0 .17.113.283.283.283H26.6v-.567h-8.783V16.567Z" fill="#444546"/><path d="m29.63 22.036-.793-1.87c-.028-.113-.141-.17-.255-.17-.113 0-.198.057-.255.17l-.793 1.87a.257.257 0 0 0-.085.198v10.484c0 .17.114.283.284.283h1.7c.17 0 .283-.113.283-.283V22.234c0-.085-.029-.17-.085-.198Zm-1.614.482h1.133v8.216h-1.133v-8.216Zm.567-1.559.425.992h-.85l.424-.992Zm.566 11.475h-1.133v-1.133h1.133v1.133ZM33.966 26.77H30.85c-.17 0-.284.113-.284.283v5.666c0 .17.114.284.284.284h3.116c.17 0 .284-.114.284-.284v-5.666c0-.17-.114-.284-.284-.284Zm-.283.566v1.417h-2.55v-1.417h2.55Zm-2.55 5.1V29.32h2.55v3.116h-2.55ZM24.9 19.122c0-.935-.765-1.7-1.7-1.7-.935 0-1.7.765-1.7 1.7 0 .935.765 1.7 1.7 1.7.935 0 1.7-.765 1.7-1.7Zm-2.833 0c0-.623.51-1.134 1.133-1.134s1.133.51 1.133 1.134c0 .623-.51 1.133-1.133 1.133s-1.133-.51-1.133-1.133ZM24.9 21.672h-3.4v.566h3.4v-.566ZM24.9 23.078h-3.4v.567h3.4v-.567ZM23.2 24.793h-4.25v.567h4.25v-.567ZM26.316 26.2h-7.367v.566h7.367v-.567ZM26.316 27.617h-7.367v.567h7.367v-.567ZM21.216 29.043h-2.267v.567h2.267v-.567ZM22.066 30.45h-3.117v.566h3.117v-.567ZM25.466 29.043h-2.267v.567h2.267v-.567ZM26.316 30.45h-3.117v.566h3.117v-.567Z" fill="#444546"/></svg>
            `, 
            class: 'side_active my-auto px-2 py-3 w-full rounded bg-menuBG text-sideBarBg hover:text-white hover:bg-red-700', 
            show: true
        },
        {
            slug: 'my-candidate',
            name: 'ed-menu-txt2', 
            link: 'candidates', 
            svg: `
                   <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.5" stroke="#fff"/><circle cx="25" cy="25" r="20.75" fill="#EAEAEA" stroke="#fff" stroke-width=".5"/><path d="M26.087 24.339a4.169 4.169 0 0 0 4.169-4.17 4.17 4.17 0 1 0-4.168 4.17Z" fill="#444546"/><path d="m27.16 24.508-1.038 1.045-1.007-1.056-.01.001c-.167.04-.88.267-1.806.931a7.274 7.274 0 0 1-1.035.612 4.69 4.69 0 0 1-1.023.364 1.959 1.959 0 0 1-.438.039.637.637 0 0 1-.274-.063c-.04-.01-.071-.048-.116-.07-.035-.046-.086-.068-.124-.135-.02-.029-.044-.053-.066-.085l-.06-.11c-.045-.065-.074-.162-.11-.245-.041-.08-.062-.186-.092-.279a2.04 2.04 0 0 1-.07-.293c-.047-.194-.069-.397-.092-.583-.027-.183-.026-.367-.04-.523v-.012c.088-.127.208-.24.354-.347.146-.104.26-.279.337-.445.295-.657.424-1.033.636-1.722.09-.29-.446-.612-.795.21-.148.297-.083.328-.233.624l-.054.101s-.031-.286-.035-.373c-.047-.992-.089-1.524-.137-2.516-.013-.275-.127-.397-.34-.383-.202.015-.29.14-.28.407.022.541.048.622.07 1.163.01.204-.123.165-.122 0-.035-.739-.069-.902-.098-1.64-.009-.2-.092-.332-.297-.354-.199-.02-.335.144-.325.395.03.713.065.852.095 1.565.01.211-.12.178-.12.02-.027-.566-.043-.673-.084-1.237-.008-.114-.077-.263-.167-.323-.218-.145-.456.04-.446.341.02.632.055.803.081 1.434.016.162-.111.206-.118.017-.02-.402-.035-.344-.057-.745-.01-.183-.114-.288-.298-.295-.186-.007-.288.101-.318.276-.013.072-.006.147-.003.22.042.952.068 1.445.138 2.394.025.332.065.564.12.833.054.268.203.395.24.527-.01.154-.02.316-.013.501.003.246.005.512.039.809.012.145.027.296.06.458.03.16.05.32.103.493.05.172.089.344.17.528l.113.274c.045.092.1.186.151.279.095.186.247.37.388.55.167.173.336.347.545.485.401.287.88.479 1.319.552.44.079.854.08 1.223.044.1-.011.22-.024.316-.039l.003 4.874h8.621v-1.866c.19.538.37 1.159.54 1.866h3.325c-.983-4.562-3.557-8.277-7.246-9.493Zm-1.029 9.297h-.018l-1.006-1.376 1.006-6.72h.018l1.007 6.72-1.007 1.376Z" fill="#444546"/></svg>
            `, 
            class: 'side_active my-auto px-2 py-3 w-full rounded bg-menuBG text-sideBarBg hover:text-white hover:bg-red-700', 
            show: true
        },
        {
            slug: 'my-jobs',
            name: 'jd-txt3', 
            link: 'manage-jobs', 
            svg: `
                   <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.5" stroke="#fff"/><circle cx="25" cy="25" r="20.75" fill="#EAEAEA" stroke="#fff" stroke-width=".5"/><path d="M23.593 25.597c1.06 0 3.55-1.265 3.55-4.298 0-.424-.01-.832-.045-1.218-.057-.76-.214-1.434-.604-1.95-.5-.702-1.377-1.131-2.9-1.131-1.524 0-2.4.429-2.9 1.13-.391.517-.548 1.19-.606 1.95a13.58 13.58 0 0 0-.045 1.219c0 3.033 2.49 4.298 3.55 4.298Zm1.16-7.022-.26.648c-.667-.187-1.132-.187-1.8 0l-.259-.648c.836-.251 1.483-.251 2.318 0ZM20.57 20.64c.041.089.215.43.484.462.31.037.822-1.087 2.538-1.09 1.717.003 2.23 1.127 2.54 1.09.268-.032.442-.373.483-.462.008.218.011.438.011.658 0 1.482-.66 2.4-1.212 2.91-.697.642-1.471.875-1.822.875-.35 0-1.123-.233-1.82-.875-.554-.51-1.213-1.428-1.213-2.91 0-.22.003-.44.011-.658Z" fill="#444546"/><path d="M26.747 26.233c.114-.113.235-.217.362-.313-.493-.266-.97-.52-1.28-.684l-.015-.008a.25.25 0 0 0-.27.024c-.4.31-.837.52-1.3.623a.248.248 0 0 0-.178.153l-.471 1.234-.472-1.234a.248.248 0 0 0-.178-.153 3.245 3.245 0 0 1-1.299-.623.25.25 0 0 0-.271-.024c-.753.4-2.558 1.365-3.067 1.705-.861.577-1.238 2.662-1.305 3.074a.246.246 0 0 0 .011.122c.031.086.822 2.116 6.58 2.116.261 0 .511-.004.752-.012.021-.264.133-.523.336-.724l1.384-1.374a3.416 3.416 0 0 1 .68-3.902Z" fill="#444546"/><path d="M31.3 26.572a2.97 2.97 0 0 0-2.106-.865 2.97 2.97 0 0 0-2.106.865 2.946 2.946 0 0 0-.485 3.548l.058.101-1.638 1.626a.673.673 0 0 0 0 .956c.129.127.3.197.482.197.181 0 .352-.07.48-.198l1.638-1.625.102.057c.445.25.953.383 1.47.383.796 0 1.543-.306 2.105-.864.561-.557.87-1.3.87-2.09 0-.791-.309-1.534-.87-2.091Zm-.73 3.457a1.94 1.94 0 0 1-1.376.565c-.52 0-1.01-.2-1.377-.565a1.924 1.924 0 0 1 0-2.733 1.94 1.94 0 0 1 1.377-.565 1.94 1.94 0 0 1 1.945 1.931c0 .517-.202 1.002-.569 1.367Z" fill="#444546"/></svg>
            `, 
            class: 'menu_active my-auto px-2 py-3 w-full rounded bg-menuBG text-sideBarBg hover:text-white hover:bg-red-700', 
            show: true
        },
        {
            slug: 'user-list',
            name: 'sd-menu-txt5', 
            link: 'user-list', 
            svg: `
                   <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.5" stroke="#fff"/><circle cx="25" cy="25" r="20.75" fill="#EAEAEA" stroke="#fff" stroke-width=".5"/><path d="M24.798 26.365a4.19 4.19 0 0 0 4.189-4.19A4.179 4.179 0 0 0 24.798 18a4.187 4.187 0 0 0-4.189 4.176c0 2.3 1.876 4.189 4.19 4.189Zm0-7.273a3.093 3.093 0 0 1 3.084 3.084 3.093 3.093 0 0 1-3.084 3.084 3.093 3.093 0 0 1-3.084-3.084 3.093 3.093 0 0 1 3.084-3.084ZM17.552 32.905h14.545a.548.548 0 0 0 .553-.552 5.262 5.262 0 0 0-5.255-5.255h-5.14A5.262 5.262 0 0 0 17 32.353c0 .308.244.552.552.552Zm4.703-4.702h5.14a4.147 4.147 0 0 1 4.111 3.597H18.143a4.159 4.159 0 0 1 4.112-3.597Z" fill="#444546"/></svg>
            `, 
            class: 'side_active my-auto px-2 py-3 w-full rounded bg-menuBG text-sideBarBg hover:text-white hover:bg-red-700', 
            show: true
        },
        {
            slug: 'my-projects',
            name: 'jd-txt4', 
            link: 'home', 
            svg: `
                   <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.5" stroke="#fff"/><circle cx="25" cy="25" r="20.75" fill="#EAEAEA" stroke="#fff" stroke-width=".5"/><path d="M32 18.093c0-.604-.497-1.093-1.11-1.093H18.11c-.613 0-1.11.49-1.11 1.093v13.814c0 .604.497 1.093 1.11 1.093h12.78c.613 0 1.11-.49 1.11-1.093V18.093Zm-9.089 13.38h-3.62V28.2h3.62v3.273Zm0-4.873h-3.62v-3.2h3.62v3.2Zm0-4.8h-3.62v-3.273h3.62V21.8Zm4.16 9.718-1.943-1.913.828-.814 1.116 1.098 1.747-1.72.828.815-2.575 2.534Zm0-4.843-1.943-1.913.828-.815 1.116 1.098 1.747-1.72.828.815-2.575 2.535Zm0-4.844-1.943-1.913.828-.815 1.116 1.098 1.747-1.72.828.815-2.575 2.535Z" fill="#444546"/></svg>
            `, 
            class: 'side_active my-auto px-2 py-3 w-full rounded bg-menuBG text-sideBarBg hover:text-white hover:bg-red-700', 
            show: true
        },
        {
            slug: 'manage-projects',
            name: 'ed-menu-txt4', 
            link: 'projects', 
            svg: `<svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.5" stroke="white"/><circle cx="25" cy="25" r="20.75" fill="#EAEAEA" stroke="white" stroke-width="0.5"/><path d="M32 18.0925C32 17.4892 31.503 17 30.89 17H18.11C17.497 17 17 17.4892 17 18.0925V31.9074C17 32.5108 17.497 33 18.11 33H30.8899C31.503 33 31.9999 32.5108 31.9999 31.9075V18.0925H32ZM22.9113 31.4727H19.2906V28.2H22.9113V31.4727ZM22.9113 26.6H19.2906V23.4H22.9113V26.6ZM22.9113 21.8H19.2906V18.5273H22.9113V21.8ZM27.0716 31.5185L25.1279 29.6054L25.9558 28.7905L27.0716 29.8888L28.8192 28.1688L29.6471 28.9837L27.0716 31.5185ZM27.0716 26.6749L25.1279 24.7617L25.9558 23.9468L27.0716 25.0451L28.8192 23.3251L29.6471 24.14L27.0716 26.6749ZM27.0716 21.8312L25.1279 19.918L25.9558 19.1031L27.0716 20.2014L28.8192 18.4814L29.6471 19.2963L27.0716 21.8312Z" fill="#444546"/></svg>

            `, 
            class: 'side_active my-auto px-2 py-3 w-full rounded bg-menuBG text-sideBarBg hover:text-white hover:bg-red-700', 
            show: true
        },
        {
            slug: 'advertisements',
            name: 'msjf-sec3-txt2', 
            link: 'advertisements-list', 
            svg: `
                <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.5" stroke="#fff"/><circle cx="25" cy="25" r="20.75" fill="#EAEAEA" stroke="#fff" stroke-width=".5"/><path d="M32.999 23.626a2.5 2.5 0 0 0-1.875-2.421v-2.579c0-.49-.54-.79-.956-.53l-4.848 3.03H20.5a.625.625 0 0 0-.625.625V23h-1.25a.625.625 0 0 0 0 1.25h1.25v1.25c0 .345.28.624.625.624h.625v5c0 1.033.842 1.875 1.875 1.875a1.879 1.879 0 0 0 1.875-1.875v-5h.445l4.848 3.03c.416.26.956-.039.956-.53v-2.578A2.5 2.5 0 0 0 33 23.626Zm-11.874-1.25h3.75v2.5h-3.75v-2.5Zm2.5 8.75a.629.629 0 0 1-.625.624.629.629 0 0 1-.625-.625v-5h1.25v5Zm6.25-3.628-3.75-2.344v-3.057l3.75-2.344v7.745Zm1.25-2.79v-2.165a1.25 1.25 0 0 1 0 2.165Z" fill="#444546"/></svg>
            `, 
            class: 'side_active my-auto px-2 py-3 w-full rounded bg-menuBG text-sideBarBg hover:text-white hover:bg-red-700', 
            show: true
        },
        {
            slug: 'job-fairs',
            name: 'sec11-tab3-title', 
            link: 'myjobfairs', 
            svg: `
                <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.5" stroke="#fff"/><circle cx="25" cy="25" r="20.75" fill="#EAEAEA" stroke="#fff" stroke-width=".5"/><path d="M23.637 18.799h1.416c.537 0 .984.383 1.086.89h.809A1.91 1.91 0 0 0 25.053 18h-1.416a1.91 1.91 0 0 0-1.895 1.688h.808c.103-.508.55-.89 1.087-.89ZM20.032 23.093c1.147.742 2.679 1.15 4.314 1.15 1.636 0 3.166-.408 4.315-1.15 1.031-.666 1.63-1.526 1.704-2.438a1.504 1.504 0 0 0-1.18-.57h-9.676c-.478 0-.905.223-1.18.57.073.912.672 1.77 1.703 2.438ZM23.8 25.31c0 .25.204.452.453.452h.187c.249 0 .452-.203.452-.452v-.36H23.8v.36Z" fill="#444546"/><path d="M19.508 27.805h6.7v-1.017c0-.123.05-.24.138-.326l1.528-1.503a.457.457 0 0 1 .321-.131h2.495v-3a3.732 3.732 0 0 1-.232.407c-.343.526-.833.995-1.453 1.396-.963.623-2.17 1.029-3.476 1.184v.495c0 .602-.488 1.091-1.09 1.091h-.187a1.091 1.091 0 0 1-1.091-1.09v-.496c-1.307-.153-2.513-.56-3.476-1.184-.62-.4-1.11-.87-1.453-1.396a3.732 3.732 0 0 1-.232-.407v4.47c0 .831.676 1.507 1.508 1.507Z" fill="#444546"/><path d="M28.535 25.191a.82.82 0 0 0-.575.24l-1.104 1.112a.803.803 0 0 0-.235.57v4.744a.81.81 0 0 0 .81.81h4.533a.81.81 0 0 0 .81-.81V26a.809.809 0 0 0-.809-.809H28.535Zm2.553.799h.876a.01.01 0 0 1 .011.011v5.86c-.002.005-.005.008-.01.008H27.431a.01.01 0 0 1-.011-.01v-4.582h.586a.663.663 0 0 0 .663-.662v-.625H31.089Z" fill="#444546"/><path d="M29.979 27.805h-1.74a.401.401 0 0 0-.4.397v.002c0 .22.18.4.4.4h2.918a.4.4 0 0 0 0-.8h-1.179ZM31.157 30.32H28.24a.4.4 0 0 0 0 .799h2.918a.4.4 0 0 0 0-.799ZM31.157 29.066H28.24a.4.4 0 0 0 0 .799h2.918a.4.4 0 0 0 0-.799Z" fill="#444546"/></svg>
            `, 
            class: 'side_active my-auto px-2 py-3 w-full rounded bg-menuBG text-sideBarBg hover:text-white hover:bg-red-700', 
            show: true
        },
        {
            slug: 'referrals',
            name: 'sd-menu-txt11', 
            link: 'referrals', 
            svg: `
                <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.5" stroke="#fff"/><circle cx="25" cy="25" r="20.75" fill="#EAEAEA" stroke="#fff" stroke-width=".5"/><path fill-rule="evenodd" clip-rule="evenodd" d="M27.908 22.82a2.909 2.909 0 1 1-5.818 0 2.909 2.909 0 0 1 5.818 0Zm-1.455 0a1.455 1.455 0 1 1-2.909 0 1.455 1.455 0 0 1 2.91 0Z" fill="#444546"/><path fill-rule="evenodd" clip-rule="evenodd" d="M25 17a8 8 0 1 0 0 16 8 8 0 0 0 0-16Zm-6.546 8c0 1.52.519 2.92 1.388 4.03a6.535 6.535 0 0 1 5.205-2.576c2.095 0 3.96.985 5.158 2.516A6.545 6.545 0 1 0 18.455 25ZM25 31.546c-1.564 0-3-.549-4.125-1.464a5.085 5.085 0 0 1 4.172-2.173c1.705 0 3.214.838 4.138 2.124A6.52 6.52 0 0 1 25 31.545Z" fill="#444546"/></svg>
            `, 
            class: 'side_active my-auto px-2 py-3 w-full rounded bg-menuBG text-sideBarBg hover:text-white hover:bg-red-700', 
            show: true
        },
        {
            slug: 'payment-history',
            name: 'ed-menu-txt5', 
            link: 'payment-history', 
            svg: `
                <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.5" stroke="#fff"/><circle cx="25" cy="25" r="20.75" fill="#EAEAEA" stroke="#fff" stroke-width=".5"/><path d="M31.929 21.096h.1l-.755-2.922c-.202-.857-1.109-1.36-1.915-1.109l-11.186 2.973c-.856.201-1.36 1.108-1.108 1.915l.957 3.627v-2.166a2.365 2.365 0 0 1 2.369-2.368h11.538v.05Z" fill="#444546"/><path d="M31.928 21.902H20.34a1.57 1.57 0 0 0-1.562 1.562v7.356a1.57 1.57 0 0 0 1.562 1.562h11.59a1.57 1.57 0 0 0 1.56-1.562v-7.355a1.57 1.57 0 0 0-1.56-1.563Zm.504 8.919a.504.504 0 0 1-.504.504H20.34a.505.505 0 0 1-.504-.504v-1.864h12.647v1.864h-.05Zm0-5.492H19.835v-1.864c0-.303.252-.504.504-.504h11.59c.302 0 .503.252.503.504v1.864Z" fill="#444546"/></svg>
            `, 
            class: 'side_active my-auto px-2 py-3 w-full rounded bg-menuBG text-sideBarBg hover:text-white hover:bg-red-700', 
            show: true
        },
        {
            slug: 'scout-mail',
            name: 'eanr-txt1', 
            link: 'scout-mail', 
            svg: `
                <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.5" stroke="#fff"/><circle cx="25" cy="25" r="20.75" fill="#EAEAEA" stroke="#fff" stroke-width=".5"/><path d="M22.098 25.73 17 30.046v-9.113l5.098 4.797ZM31.133 20H17.867l6.633 6.243L31.133 20Zm-4.23 5.73L32 30.046v-9.113l-5.098 4.797Zm-.936.88-1.048.986a.613.613 0 0 1-.84 0l-1.049-.986L17.848 31h13.304l-5.185-4.39Z" fill="#444546"/></svg>
            `, 
            class: 'side_active my-auto px-2 py-3 w-full rounded bg-menuBG text-sideBarBg hover:text-white hover:bg-red-700', 
            show: true
        },
        {
            slug: 'block-list',
            name: 'eanr-txt2', 
            link: 'block', 
            svg: `
                <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="24.5" stroke="#fff"/><circle cx="25" cy="25" r="20.75" fill="#EAEAEA" stroke="#fff" stroke-width=".5"/><path d="M34.519 32.972H20.078a1.078 1.078 0 1 1 0-2.156h14.44a1.078 1.078 0 0 1 0 2.156ZM34.519 26.206H20.078a1.078 1.078 0 1 1 0-2.155h14.44a1.078 1.078 0 0 1 0 2.155ZM34.519 19.44H20.078a1.078 1.078 0 1 1 0-2.155h14.44a1.078 1.078 0 0 1 0 2.155ZM15.447 19.894a1.447 1.447 0 1 0 0-2.894 1.447 1.447 0 0 0 0 2.894ZM15.447 26.574a1.447 1.447 0 1 0 0-2.894 1.447 1.447 0 0 0 0 2.894ZM15.447 33.258a1.447 1.447 0 1 0 0-2.895 1.447 1.447 0 0 0 0 2.895Z" fill="#444546"/></svg>
            `, 
            class: 'side_active my-auto px-2 py-3 w-full rounded bg-menuBG text-sideBarBg hover:text-white hover:bg-red-700', 
            show: true
        },
    ],

    feedback: {
        feedbackCategory: [
            {name: 'sec10-title', value:'employer'},
            {name: 'head-js', value:'job-seeker'},
            {name: 'sec11-tab3-title', value:'job-fairs'},
            {name: 'sec11-tab4-title', value:'promotions'},
            {name: 'head-rs', value:'relocation-service'},
            {name: 'sec11-tab5-title', value:'general'},
        ]
    },
}
