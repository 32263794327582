export const getAdvertisementData = (state) => {
    return state.advertisement.advertisementData;
}

export const getAdvertisementLink = (state) => {
    return state.advertisement.advertisementLinks;
}

export const getAdvertisementMeta = (state) => {
    return state.advertisement.advertisementMeta;
}

export const getSearchAdv = (state) =>{
    let ads = state.adv;
    let data = {
        ad_title:ads.ad_title,
        ad_type:ads.ad_type
    }
    return data;
}

export const getSearchedJobs = (state) => {
    return state.searchedJobs
}

export const getSearchedJobPagination = (state) => {
    return state.searchedJobsPagination
}

export const getCurrentFilterType = (state) =>{
    return state.currentFilterType
}

export const getEducationIds = (state) => {
    return state.educationQueryIds
}

export const getResultCount = (state) =>{
    return state.resultCount
}

export const getPositionData = (state) =>{
    return state.position
}

export const getTitleData = (state) =>{
    return state.title
}

export const getWorkStyleData = (state) =>{
    return state.workstyle
}

export const getIndustryData = (state) =>{
    return state.industry
}

export const getIndustrySubData = (state) =>{
    return state.industrySub
}

export const getStrengthData = (state) =>{
    return state.strength
}

export const getKeySkillData = (state) =>{
    return state.keySkill
}

export const getFunctionAreaData = (state) =>{
    return state.functionArea
}

export const getLanguageData = (state) =>{
    return state.language
}

export const getEducationData = (state) =>{
    return state.education
}

export const getCourseMinorData = (state) =>{
    return state.courseMinor
}

export const getTopCompanies = (state) =>{
    return state.topCompanies
}

export const getEmployerTypeData = (state) =>{
    return state.employerType
}

export const getExperienceData = (state) =>{
    return state.experience
}

export const getPageQuery = (state) =>{
    return state.pageQuery
}

export const getSearchedEmployers = (state) => {
    return state.searchedEmployers
}

export const getSearchedEmployerPagination = (state) => {
    return state.searchedEmployersPagination
}

export const getEmployerPageQuery = (state) =>{
    return state.empPageQuery
}

export const getEmployerResultCount = (state) =>{
    return state.empResultCount
}

export const getBlockModule = (state) =>{
    return state.isBlockModule
}

export const getEmp = (state) => {
	return state.emp
}
export const getEmpJobs = (state) => {
	return state.empJobs.data
}