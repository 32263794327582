import { isEmpty } from 'lodash';

// list of feedback
export const fetchFeedbackPublishList = ({commit, dispatch, getters}, query) => {
    let categoryName = getters['getCategoryList']
    if(query){
        query = query + '&status=1&category=' + categoryName;
    }else{
        query = '?status=1&category=' + categoryName;
    }
    return window.axios.get(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/feedback-review' + query).then((response) => {          
        commit('setFeedbackPublishList', response);   
        return response.data;
    })
}
// ~~~~~~~~~

// Add feedback
export const addFeedback = ({ commit }, { payload, context }) => {
  return window.axios.post(process.env.VUE_APP_SMART_RECRUIT_API_URL + 'api/feedback-review/0', payload, {
      config: { headers: { "Content-Type": "multipart/form-data" } },
    })
    .then((response) => {
    //   commit("SET_ALERT", "success", { root: true });
    //   commit("SET_ALERT_MESSAGE", response.data.message, { root: true });
      // location.reload();      
      return response.data.success;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data
        return false
    })
}
// 