export default {
    currentFilterType: '',
    commonSearchQuery: '',
    locationSearchQuery: '',
    matchingPercetageSearchQuery: '',
    daysSearchQuery: '',
    titpSearchQuery: '',
    salaryFromQuery: '',
    salaryToQuery: '',
    salaryCurrencyQuery: '',
    salaryRangeQuery: '',
    jobTypeQuery: '',
    cityQuery: '',
    stateQuery: '',
    countryQuery: '',
    experienceQuery: '',
    positionQuery: '',
    workStyleQuery: '',
    strengthQuery: '',
    skillQuery: '',
    titleQuery: '',
    functionQuery: '',
    educationQuery: '',
    educationQueryIds: '',
    languageQuery: '',
    industryQuery: '',
    courseQuery: '',
    industrySubQuery: '',
    perPageQuery: '',
    pageQuery: '',
    resultCount: '',
    position: '',
    title: '',
    workstyle: '',
    industry: '',
    industrySub: '',
    strength: '',
    keySkill: '',
    functionArea: '',
    language: '',
    education: '',
    courseMinor: '',
    employerType: '',
    experience: [
        {id:'[0]', checkDays:false, name:'Fresher'},
        {id:'[1]', checkDays:false, name:'6-12 Months'},
        {id:'[2,3]', checkDays:false, name:'1-3 Years'},
        {id:'[4,5]', checkDays:false, name:'3-5 Years'},
        {id:'[6,7,8,9,10]', checkDays:false, name:'5-10 Years'},
        {id:'10', checkDays:false, name:'More than 10 years'},
    ],
    employerTypeQuery: '',
    advertisement:{
        advertisementData:null,
        advertisementLinks:null,
        advertisementMeta:null,
    },
    adv:{
        ad_title:'',
        ad_type:'',
    },
    searchedJobs: [],
    searchedJobsPagination: {
        links: {},
        meta: {}
    },
    topCompanies:[],
    companySearchQuery:'',
    searchedEmployers: [],
    searchedEmployersPagination: {
        links: {},
        meta: {}
    },
    empPageQuery: '',
    empResultCount: '',
    perPageEmployerQuery: '',
    empCompanyNameSearchQuery: '',
    empLocationSearchQuery: '',
    empTitpSearchQuery: '',
    empIndustryQuery: '',
    empIndustrySubQuery: '',
    empCityQuery: '',
    empStateQuery: '',
    empCountryQuery: '',
    empEmployerTypeQuery: '',
    isBlockModule: '',
    emp: [],
	empJobs: {
		data:null,
	},
}