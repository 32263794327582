import auth from './auth/routes'
import home from './home/routes'
import profile from './profile/routes'
import employerProfileForm from "./employerProfileForm/routes";
import jobseekerProfileForm from "./jobseekerProfileForm/routes";
import roleAndPermission from "./roleAndPermission/routes";
import jobs from "./jobs/routes";
import referrals from "./referrals/routes";
import users from "./users/routes"
import myTasks from "./myTasks/routes"
import relocationService from "./relocationService/routes"
import advancedSearch from "./advancedSearch/routes"
import agreements from "./agreements/routes"
import employerJobFair from "./employerJobFair/routes"
import advertisements from "./advertisements/routes"
import candidates from "./candidates/routes"
import projects from "./projects/routes"
import blockList from "./blockList/routes"
import aboutUs from "./aboutUs/routes"
import landingPage from "./landingPage/routes"
import dashboard from "./dashboard/routes"
import scoutMail from "./scoutMail/routes"
import paymentHistory from "./paymentHistory/routes"
import addNewLanguage from "./addNewLanguage/routes"
import jobseekerMyProfile from "./jobseekerMyProfile/routes";


export default [...projects,...candidates,...advertisements,...agreements, ...auth, ...home, ...profile,
 ...jobseekerProfileForm, ...jobseekerMyProfile, 
...employerProfileForm, ...roleAndPermission, ...jobs, ...referrals, ...users, ...myTasks, ...relocationService, 
...advancedSearch, 
...employerJobFair, 
...blockList, ...aboutUs, ...landingPage, ...dashboard, ...scoutMail, ...paymentHistory, ...addNewLanguage];

