<template>
    <div>  
        <div class="opacity-25 fixed inset-0 z-50 bg-black"></div>
        <div>   
            <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
                <div class="relative w-full mx-4 md:mx-0 px-4 md:px-0">       
                    <div class="border-2 rounded-2xl shadow-lg rounded-2xl relative flex  bg-white outline-none focus:outline-none max-w-lg mx-auto">        
                        <div class="p-5 w-full">
                            <div class="flex justify-between">
                                <div class="px-0 md:px-6">
                                    <label class="text-xl md:text-2xl font-bold border-l-4 uppercase border-red-600 px-2 tracking-wide"><!-- Review -->{{$t('sec11-title')}}
                                    </label>
                                </div>
                                <div class="relative">
                                    <button class="absolute inset-x-2 -top-7" type="button" @click="showAddFeedback(false)">
                                        <svg class=" bg-white rounded-full" width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 .424c-5.522 0-10 4.305-10 9.615 0 5.31 4.478 9.616 10 9.616s10-4.306 10-9.616S15.522.424 10 .424Zm3.692 13.268-1.473-.006L10 11.142l-2.217 2.542-1.475.006a.174.174 0 0 1-.179-.172c0-.04.016-.08.043-.111l2.904-3.327-2.904-3.325a.175.175 0 0 1-.043-.111c0-.095.08-.172.179-.172l1.475.007L10 9.022l2.216-2.541 1.474-.007c.098 0 .178.075.178.172 0 .04-.015.08-.042.112l-2.9 3.324 2.902 3.327c.027.032.043.07.043.111 0 .095-.08.172-.179.172Z" fill="#DA5352"/></svg>
                                    </button>
                                </div>
                            </div>

                            <form @submit.prevent="submitFeddback">
                                <div class="px-0 md:px-6 py-6 space-x-2 md:space-x-0 space-y-4">
                                 <div class="flex">
                                    <div class="w-5/12">
                                        <div>
                                            <label>
                                                <img v-if="authenticated == true" :src="this.$store.state.myprofile.img" class="w-28 h-28 border-2 rounded-md object-cover">
                                                <img v-else src="images/user1.webp" class="w-28 h-28 border-2 rounded-md object-cover" >
                                            </label>
                                        </div>                                        
                                    </div>
                                    <div class="w-7/12 space-y-4">
                                        <div class="flex flex-col">
                                            <select v-model="category" :disabled="authenticated == true" v-bind:class="{ 'border border-2 border-red-300': errors.category  }" class="border border-gray-300 rounded-md pl-4 pr-8 py-1.5 font-semibold text-xs focus:outline-none focus:ring-1 focus:ring-blue-600 bg-transparent w-full text-blue-600">
                                                <option class="text-gray-400" value="" hidden><!-- Select Category -->{{$t('select-category')}}</option>
                                                <option v-for="(category, i) in feedCategory" :key="i" :value="category.value">{{$t(category.name)}}</option>
                                            </select>

                                            <span v-if="errors.category" class="pt-1 text-xs text-red-400">{{$t('category-field-is-required')}}</span>
                                        </div>

                                        <div class="w-full flex-col rounded-md space-y-3">
                                            <input type="text" v-model="first_name" :disabled="authenticated == true" v-bind:class="{ 'border border-2 border-red-300': errors.first_name  }" name="first_name" class="px-4 py-1 placeholder-blue-300 text-sm border border-gray-300 text-blue-600 sm:text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 w-full" :placeholder="$t('sec12-col2-text1')"> 
                                            <span v-if="errors.first_name" class="pt-1 text-xs text-red-400">{{$t('msrs-para2')}}</span>                   
                                        </div>
                                        <div class="w-full flex-col rounded-md space-y-3">
                                            <input type="text" v-model="last_name" :disabled="authenticated == true" v-bind:class="{ 'border border-2 border-red-300': errors.last_name  }"  name="last_name" class="px-4 py-1 placeholder-blue-300 border border-gray-300 text-sm text-blue-600 sm:text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 w-full" :placeholder="$t('sec12-col2-text2')">        
                                            <span v-if="errors.last_name" class="pt-1 text-xs text-red-400">{{$t('msrs-para3')}}</span>
                                        </div>                                        
                                    </div>
                                </div>

                                <div class="w-full">
                                    <input type="text" v-model="email" :disabled="authenticated == true" v-bind:class="{ 'border border-2 border-red-300': errors.email  }" name="email" class="px-4 py-1 placeholder-blue-300 text-sm border border-gray-300 text-blue-600 sm:text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 w-full" placeholder="email@xyz.com">
                                    <span v-if="errors.email" class="pt-1 text-xs text-red-400">{{$t('eau-para6')}}</span>
                                </div>

                                <div class="w-full" for="message">
                                    <textarea rows="3" type="text" v-bind:class="{ 'border border-2 border-red-300': errors.message  }" name="message" id="message" v-model="message" class="px-4 py-1 placeholder-blue-300 text-sm border border-gray-300 text-gray-900 sm:text-sm rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600 w-full" :placeholder="$t('please-write-your-comment')">
                                    </textarea>
                                    <span v-if="errors.message" class="text-xs text-red-400">{{$t('message-field-is-required')}}</span>
                                </div>

                                <div class="md:flex space-x-4">
                                    <div><span class="text-blue-600 text-sm"><!-- Rate Us -->{{$t('r-txt')}}</span></div>
                                    <div>
                                        <div class="flex space-x-1">
                                            <svg class="cursor-pointer fill-current" v-on:change="star" v-for="(i,key) in stars" :value="key ++" :class="i.class" @click="handleStars(i.number)" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill=""><g><rect fill="none" height="24" width="24"/><path d="M14.43,10l-1.47-4.84c-0.29-0.95-1.63-0.95-1.91,0L9.57,10H5.12c-0.97,0-1.37,1.25-0.58,1.81l3.64,2.6l-1.43,4.61 c-0.29,0.93,0.79,1.68,1.56,1.09L12,17.31l3.69,2.81c0.77,0.59,1.85-0.16,1.56-1.09l-1.43-4.61l3.64-2.6 c0.79-0.57,0.39-1.81-0.58-1.81H14.43z"/></g></svg>
                                        </div>
                                    <span v-if="errors.star" class="pt-2 text-xs text-red-400">{{$t('star-field-is-required')}}</span>
                                    </div>
                                </div>                                        

                                <div class="flex justify-end">
                                    <button type="submit" :disabled="isbeingSubmit" class="md:w-36 w-28 text-sm border bg-greenBg hover:bg-green-700 text-white font-semibold justify-center block flex md:py-2 py-1 rounded-3xl">
                                        <svg v-if="isbeingSubmit" class="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" ><circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle><path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path></svg>
                                        <span v-show="!isbeingSubmit" class="capitalize"><!-- Submit Review -->{{$t('ero-title2')}}</span>
                                    </button>
                                </div>
                                </div>
                            </form>
                        </div>                                 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapMutations } from 'vuex'
import { mapGetters } from 'vuex'
import { mapActions } from 'vuex'
export default  ({
  data: function() {
    return {        
        isbeingSubmit: false,        
        first_name: '',
        last_name: '',
        email: '',
        message: '',
        star: '',
        // status: '',
        category: 'general',
        user_id: '',

        stars: [
        {number: '1', class:'text-gray-400'},
        {number: '2', class:'text-gray-400'},
        {number: '3', class:'text-gray-400'},
        {number: '4', class:'text-gray-400'},
        {number: '5', class:'text-gray-400'},
        ],
        errors:[],
        submitted: false,
    }
},
components: {

},
computed: {
    ...mapGetters({
        authenticated: 'auth/authenticated',
        loggedInUser: 'auth/loggedInUser',
        feedCategory: 'getfeedbackCategory',
    }),
},
methods: {     
    ...mapMutations({
        'showAddFeedback':'feedbackReviews/showAddFeedback',
    }),

    ...mapActions({
       addFeedback: 'feedbackReviews/addFeedback',
    }),
    getUser() {
        let array = ['haken-admin', 'Haken Admin', 'oem-admin', 'OEM Admin', 'recruit-admin', 'Recruit Admin','titp-sending-org', 'TITP Sending ORG','titp-supervising-admin','TITP Supervising Admin']
        this.category = array.includes(this.$store.state.myprofile.role) ? 'employer' : this.$store.state.myprofile.role;
        this.first_name = this.$store.state.myprofile.firstname;
        this.last_name = this.$store.state.myprofile.lastname;
        this.email = this.$store.state.myprofile.email;
    },

    submitFeddback() {     
        this.isbeingSubmit = true;        
        let formData = new FormData();
        formData.append('first_name',this.first_name); 
        formData.append('last_name',this.last_name); 
        formData.append('email',this.email); 
        formData.append('category',this.category); 
        formData.append('message',this.message); 
        formData.append('star',this.star); 
        formData.append('user_id',this.user_id); 
        // formData.append('status',this.status); 
            this.addFeedback({
                payload: formData,
                context: this,
            }).then((success)=> {
                if(success){
                    this.$store.commit('SET_ALERT', 'success',{ root: true });
                    this.$store.commit('SET_ALERT_MESSAGE', this.$t('feedback-successfully-submitted'),{ root: true });
                    this.showAddFeedback(false);
                }else {
                    this.isbeingSubmit = false
                }
            })
        }, 
    handleStars: function(starNo){
        this.star = starNo
        for (var i = 0; i <= this.stars.length-1; i++) {
            if (i < starNo) {
                this.stars[i].class = 'text-yellow-500'
            } else {
                this.stars[i].class = 'text-gray-400'                    
            }
        }
    },
 
},
mounted() {
   if(this.authenticated == true){
        this.getUser();
    }
},



});
</script>

<style>
 
</style>