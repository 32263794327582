import { createStore } from 'vuex'
import state from "./state.js";
import getters from "./getters.js";
import mutations from "./mutations.js";
import actions from "./actions.js";
import RoleAndPermission from "./modules/RoleAndPermission.js";
import auth from "../app/auth/vuex";
import employerProfileForm from "../app/employerProfileForm/vuex";
import jobseekerProfileForm from "../app/jobseekerProfileForm/vuex";
import jobs from "../app/jobs/vuex";
import roleAndPermission from '../app/roleAndPermission/vuex/index.js'
import referrals from '../app/referrals/vuex/index.js'
import UserList from "./modules/UserList.js";
import employerJobFair from "../app/employerJobFair/vuex";
import users from "../app/users/vuex"
import relocationService from "../app/relocationService/vuex"
import profile from "../app/profile/vuex"
import agreements from "../app/agreements/vuex"
import advancedSearch from "../app/advancedSearch/vuex"
import advertisements from "../app/advertisements/vuex"
import blockList from "../app/blockList/vuex"
import home from "../app/home/vuex"
import feedbackReviews from "../app/feedbackReviews/vuex"
import contactUs from "../app/contactUs/vuex"
import aboutUs from "../app/aboutUs/vuex"
import landingPage from "../app/landingPage/vuex"
import dashboard from "../app/dashboard/vuex"
import candidates from "../app/candidates/vuex"
import scoutMail from "../app/scoutMail/vuex"
import paymentHistory from "../app/paymentHistory/vuex"
import projects from "../app/projects/vuex"
import addNewLanguage from "../app/addNewLanguage/vuex"
import jobseekerMyProfile from "../app/jobseekerMyProfile/vuex"
import ChangePasswordModel from "./modules/ChangePasswordModel.js";
export default createStore({
  state,
  getters,
  mutations,
  actions,
 
  
  modules: {
    auth: auth,
    employerProfile: employerProfileForm,
    jobs: jobs,
    UserList: UserList,
    jobseekerProfile: jobseekerProfileForm,
    RoleAndPermission: RoleAndPermission,
    roleAndPermission: roleAndPermission,
    referrals: referrals,
    users: users,
    employerJobFair: employerJobFair,
    relocationService:relocationService,
    profile: profile,
    agreements:agreements,
    advancedSearch: advancedSearch,
    adv: advertisements,
    blockList: blockList,
    home: home,
    feedbackReviews: feedbackReviews,
    contactUs: contactUs,
    aboutUs: aboutUs,
    landingPage: landingPage,
    dashboard: dashboard,
    candidates:candidates,
    scoutMail:scoutMail,
    paymentHistory:paymentHistory,
    projects:projects,
    addNewLanguage:addNewLanguage,
    jobseekerMyProfile: jobseekerMyProfile,
    ChangePasswordModel: ChangePasswordModel,
  },
});

