export default [
	{
		path: '/advanced-search/job',
		component: () => import('../components/AdvancedSearch.vue'),
		name: 'advanced-search-job',
		meta: {
		}
	},
	{
		path: '/advanced-search/project',
		component: () => import('../components/AdvancedSearch.vue'),
		name: 'advanced-search-project',
		meta: {
		}
	},

	{
		path: '/advanced-search/employer',
		component: () => import('../components/AdvancedSearch.vue'),
		name: 'advanced-search-employer',
	},

	{
        path: '/view-employer/:id',
        // path: '/view-employer',
        component: () => import('../components/partials/ViewEmployer.vue'),
        name: 'view-employer',
    },


	{
		path: '/advanced-search/job-fair',
		component: () => import('../components/AdvancedSearch.vue'),
		name: 'advanced-search-job-fair',
	},

	{
		path: '/advanced-search/advertisement',
		component: () => import('../components/AdvancedSearch.vue'),
		name: 'advanced-search-advertisement',
	},
	
]