<template>
    <div>
        <nav class="border-b">
            <div class="md:flex md:justify-between px-6 bg-white space-y-2 md:space-y-0">
                <div>
                    <router-link to="/">
                        <img :src="this.$store.state.myprofile.company_logo" class="w-28 md:w-32 h-14 object-contain">
                    </router-link>
                </div>
                <div class="flex gap-4 items-center">
                    <div class="w-28 md:w-32">
                        <a :href="sekaiIchi" target="_blank" class="flex justify-center items-center rounded-full px-3 border hover:border-gray-400 bg-white">
                            <img :src="assetUrl('/images/sekailogo.webp')" class="bg-white rounded-full px-1 h-7 w-full object-contain p-0.5">
                        </a>
                    </div>            
                    <div class="w-28 md:w-32">
                        <a :href="jiipaClientUrl" target="_blank" class="flex justify-center items-center rounded-full px-3 border hover:border-gray-400 bg-white">
                            <img src="/images/jiipa.png" class="bg-white rounded-full px-1 h-7 w-full object-contain p-0.5">
                        </a>
                    </div>          
                    
                    <!-- <div class="relative inline-flex w-32">
                        <svg class="w-1.5 absolute top-0 right-0 m-3 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#fff" fill-rule="nonzero"/></svg>
                        <select class="w-full border border-gray-300 rounded-full text-white pl-4 pr-8 h-7 flex items-center font-semibold text-xs hover:border-gray-400 focus:outline-none appearance-none bg-bgGray">
                            <option>{{ $t('p-tbl2-col3-row1') }}</option>
                            <option>India{{$t('e-tbl1-col3-row2-txt')}}</option>
                            <option>Japan{{$t('p-tbl2-col3-row1-txt')}}</option>
                            <option></option>
                        </select>
                    </div> -->
                    <div class="relative inline-flex w-28 md:w-32">
                        <svg class="w-1.5 absolute top-0 right-0 m-3 pointer-events-none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412 232"><path d="M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z" fill="#fff" fill-rule="nonzero"/></svg>
                        <select class="w-full border border-gray-300 rounded-full text-white pl-4 pr-8 h-7 flex items-center font-semibold text-xs hover:border-gray-400 focus:outline-none appearance-none bg-bgGray" @change="languageHandler" v-model="locale" >
                            <option :value="language.slug" v-for="(language, index) in this.languageData" :key="index">{{ language.name }}</option>
                        </select>
                    </div> 
                </div>
            </div>

            <div :class="{'fixed top-0 w-full shadow-lg z-30': isSubmenuFixed, 'relative': !isSubmenuFixed}" class="flex justify-between gap-2 items-center px-3 xl:px-6 bg-bgGray border-b border-gray-200">
                <div class="flex justify-center w-full bg-bgGray py-2.5 px-3 xl:px-6 text-xs xl:text-sm font-semibold">
                    <div class="w-32 flex items-center justify-between">
                        <router-link :to="{name:'advanced-search-job'}" class="hidden lg:block text-white flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-s')}}</router-link>
                        <div class="hidden lg:block border-r h-6"></div>
                    </div>
                    <div class="w-32 flex items-center justify-between">
                        <router-link :to="{name:'employerHome'}" class="hidden lg:block text-white flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-e')}}</router-link>
                        <div class="hidden lg:block border-r h-6"></div>
                    </div>
                   <div class="w-40 flex items-center justify-between">
                        <router-link :to="{name:'jobSeekerHome'}" class="hidden lg:block text-white flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-js')}}</router-link>
                        <div class="hidden lg:block border-r h-6"></div>
                   </div>
                    <div class="w-36 flex items-center justify-between">
                        <router-link to="/titp?filter=job&titp=1" class="hidden lg:block text-white flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('TITP')}}</router-link>
                        <div class="hidden lg:block border-r h-6"></div>
                    </div>
                    <div class="w-48 flex items-center justify-between">
                        <router-link :to="{name:'outsourcingTender'}" class="hidden lg:block text-white flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-ot')}}</router-link>
                        <div class="hidden lg:block border-r h-6"></div>
                    </div>
                    <div class="w-40 flex items-center justify-between">
                        <router-link :to="{name:'jobFairHome'}" class="hidden lg:block text-white flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-jf')}}</router-link>
                        <div class="hidden lg:block border-r h-6"></div>
                    </div>
                    <div class="w-40 flex items-center justify-between">
                        <router-link :to="{name:'AdvertisementsHome'}" class="hidden lg:block text-white flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-a')}}</router-link>
                        <div class="hidden lg:block border-r h-6"></div>
                    </div>
                    <div class="md:w-44 flex items-center justify-between">
                        <router-link :to="{name:'relocationServiceHome'}" class="hidden lg:block text-white flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-rs')}}</router-link>
                        <div class="hidden lg:block border-r h-6"></div>
                    </div>
                    <div class="md:w-40 flex items-center justify-between">
                        <router-link :to="{name:'dashboardHome'}" class="hidden lg:block text-white flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-d')}}</router-link>
                        <div class="hidden lg:block border-r h-6"></div>
                    </div>
                    <div class="w-80 md:w-32 flex items-center justify-between" v-if="!setAuth">
                        <router-link :to="{name:'sign-in'}" class="flex items-center text-center justify-center menu_active text-red-500 hover:text-white hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-si')}}</router-link>
                    </div>

                    <div class="w-60 lg:w-32 flex items-center justify-center space-x-3"  v-if="setAuth">
                        <div class="relative cursor-pointer notification-bell">
                            <span @click="bellNotificationHandler(true)">
                                <svg class="fill-current p-1 text-white p-1 hover:bg-gray-300 rounded-full border border-gray-300" xmlns="http://www.w3.org/2000/svg" height="30px" viewBox="0 0 24 24" width="30px" fill="#fff">
                                    <path d="M0 0h24v24H0V0z" fill="none" /><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.9 2 2 2zm6-6v-5c0-3.07-1.63-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.64 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2zm-2 1H8v-6c0-2.48 1.51-4.5 4-4.5s4 2.02 4 4.5v6z" />
                                </svg>
                            </span>
                            <span v-if="bellNotifications.count > 0" class="absolute inset-x-3 -top-2 bg-red-700 rounded-full text-xs text-center text-white w-6" v-html="bellNotifications.count" />
                        </div>
                        
                        <div class="flex items-center">
                            <button @click="toggleDropIt();" type="button" class="w-10 md:w-auto">
                                <img :src="this.$store.state.myprofile.img" class="bg-white w-8 h-8 border rounded-full object-cover" > 
                            </button>
                            <button v-if="dropIt" @click="closeDropIt();" type="button" tabindex="-1" class="fixed top-0 inset-0 h-full w-full bg-white opacity-0 cursor-default z-50"></button>
                            <!-- profile dropdown -->
                            <div class="relative z-50" v-if="dropIt">
                                <ul class="absolute -right-4 top-7 w-36 bg-white space-y-1 text-sm rounded-md shadow-lg border border-gray-100" >
                                   <div class="flex flex-col">
                                     <router-link :to="{name:'my-profile'}" @click="closeDropIt();" class="hover:bg-gray-200 pl-4 py-2 rounded-t-md" v-if="this.$store.state.myprofile.role != 'job-seeker'"> <!-- My Profie -->{{$t('sd-menu-title')}}</router-link>
                                     <router-link to="/job-seeker-profile" @click="closeDropIt();" class="hover:bg-gray-200 pl-4 py-2 rounded-t-md" v-else> <!-- My Profie -->{{$t('sd-menu-title')}}</router-link>
                                    <router-link :to="{name:'Dashboard'}"  @click="closeDropIt();" class="hover:bg-gray-200 pl-4 py-2"> <!-- Dashboard --> {{$t('head-d')}}</router-link>
                                    <span class="hover:bg-gray-200 pl-4 py-2 cursor-pointer" @click="$store.commit('showChangePasswordModel', true); closeDropIt();"> <!-- Change Password -->{{$t('cp-col2-title')}} </span>
                                    <!-- <router-link to="/edit-profile/change-password" @click="closeDropIt();" class="hover:bg-gray-200 pl-4 py-2"> {{$t('cp-col2-title')}} </router-link> -->
                                    <span class="px-3"><hr></span>
                                    <router-link to="/" v-on:click="logout" class="hover:bg-gray-200 pl-4 py-3 rounded-b-md font-semibold text-gray-500 hover:text-gray-800"> <!-- Logout -->{{$t('logout')}} </router-link>
                                   </div>
                                </ul>
                            </div>  
                        </div>
                    </div>

                     <button type="button" @click="showMenu = !showMenu" class="block lg:hidden">
                        <svg class="w-7 flex items-center z-50 text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd">
                        </path></svg> 
                    </button>
                </div>
                
                <!-- <div class="py-2.5 grid grid-cols-10 flex text-center w-full divide-x divide-gray-300">
                    <div v-for="(link, i) in headerLinks" :key="i" class="my-auto px-1">
                        <router-link :to="'/'+link.link" class="text-xs xl:text-sm my-auto font-semibold hidden lg:block text-white h-full" :class="{[link.class]:true}" v-show="link.show" v-html="$t(link.name)">
                        </router-link>
                    </div>
                </div> -->
            </div>
        </nav>


        <div>
            <Transition name="slide-fade" class="fixed inset-y-0 right-0 w-full h-full font-nunito z-50">
                <div v-if="showMenu" class="flex justify-end w-full">
                    <div @click="showMenu = !showMenu" class="opacity-25 fixed inset-0 z-20 bg-black"></div>
                    <div class="border w-6/12 bg-white rounded-l-md z-30">
                        <div class="p-4 space-y-5 flex flex-col">
                            <div class="flex relative">
                                <button type="button" @click="showMenu = !showMenu" class="absolute right-0 w-10 transform hover:-translate-1 hover:scale-110 transition duration-100">
                                    <svg class="w-6 h-6 text-red-600 flex justify-end" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" ><path fill-rule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clip-rule="evenodd" /></svg>
                                </button>
                                <label class="flex flex-col space-y-6">
                                    <!-- <router-link :to="'/'+link.link" v-for="(link, i) in headerLinks" :key="i" class="text-sm my-auto font-semibold rounded" :class="{[link.class]:true}" v-show="link.show" v-html="$t(link.name)"></router-link> -->
                                        <router-link :to="{name:'advanced-search-job'}" class="flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-s')}}</router-link>
                                      
                                        <router-link :to="{name:'employerHome'}" class="flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-e')}}</router-link>
                                     
                                 
                                        <router-link :to="{name:'jobSeekerHome'}" class="flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-js')}}</router-link>
                                       
                                        <router-link to="/titp?filter=job&titp=1" class="flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('TITP')}}</router-link>
                                       
                                        <router-link :to="{name:'outsourcingTender'}" class="flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-ot')}}</router-link>
                                        
                                        <router-link :to="{name:'jobFairHome'}" class="flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-jf')}}</router-link>
                                       
                                        <router-link :to="{name:'AdvertisementsHome'}" class="flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-a')}}</router-link>
                                        
                                        <router-link to="/relocation-service" class="flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-rs')}}</router-link>
                                        
                                        <router-link :to="{name:'dashboardHome'}" class="flex items-center text-center justify-center menu_active hover:bg-red-500 mx-auto p-1.5 rounded">{{$t('head-d')}}</router-link>
                                       
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
        
        <div class="w-full h-full bg-gray-800 bg-opacity-90 top-0 overflow-y-auto overflow-x-hidden fixed sticky-0" id="chec-div">
            <div class="w-full absolute z-10 right-0 h-full overflow-x-hidden transform translate-x-0 transition ease-in-out duration-700" id="notification">
                <div class="2xl:w-4/12 bg-gray-50 h-screen overflow-y-auto p-8 absolute right-0">
                    <div class="flex items-center justify-between">
                        <p tabindex="0" class="focus:outline-none text-2xl font-semibold leading-6 text-gray-800">
                        <!-- Notifications -->{{$t('sd-cd13-title')}}
                        </p>
                        <div class="flex justify-end items-center">
                            <button @click="markAllSeen" class="text-xs font-bold text-red-500 underline p-4 bg-gray-200 hover:bg-gray-300"><!-- Mark All Seen -->{{$t('mark-all-seen')}}
                            </button>

                            <button role="button" aria-label="close modal" class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 rounded-md cursor-pointer" @click="bellNotificationHandler(false)">
                            <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/notification_1-svg1.svg" alt="icon" />
                           
                        </button>
                        </div>
                        
                    </div> 

                    <div v-for="message in bellNotifications.messages">
                        <!-- Favourite -->
                        <div class="w-full p-3 mt-8 bg-white rounded flex" v-if="message.type == 'fav'">
                            <div tabindex="0" aria-label="heart icon" role="img" class="focus:outline-none w-8 h-8 border rounded-full border-gray-200 flex items-center justify-center">
                                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/notification_1-svg2.svg"  alt="icon"/>
                            </div>
                            <div class="pl-3">
                                <p tabindex="0" class="focus:outline-none text-sm leading-none"><span class="text-indigo-700" v-html="message.message"/></p>
                                <p tabindex="0" class="focus:outline-none text-xs leading-3 pt-1 text-gray-500" v-html="message.created_at"/>
                            </div>
                        </div>
                        <!-- Add -->
                        <div class="w-full p-3 mt-4 bg-white rounded shadow flex flex-shrink-0" v-if="message.type == 'add'">
                            <div tabindex="0" aria-label="group icon" role="img" class="focus:outline-none w-8 h-8 border rounded-full border-gray-200 flex flex-shrink-0 items-center justify-center">
                                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/notification_1-svg3.svg"  alt="icon"/>
                                
                            </div>
                            <div class="pl-3 w-full">
                                <div class="flex items-center justify-between w-full">
                                    <p tabindex="0" class="focus:outline-none text-sm leading-none"><span class="text-indigo-700" v-html="message.message"/></p>
                                    <div tabindex="0" aria-label="close icon" role="button" class="focus:outline-none cursor-pointer">
                                        <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/notification_1-svg4.svg"  alt="icon"/>
                                    </div>
                                </div>
                                <p tabindex="0" class="focus:outline-none text-xs leading-3 pt-1 text-gray-500" v-html="message.created_at"/>
                            </div>
                        </div>
                        <!-- Info -->
                        <div class="w-full p-3 mt-4 bg-white rounded flex" v-if="message.type == 'info'">
                            <div tabindex="0" aria-label="post icon" role="img" class="focus:outline-none w-8 h-8 border rounded-full border-gray-200 flex items-center justify-center">
                                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/notification_1-svg5.svg"  alt="icon"/>
                            </div>
                            <div class="pl-3" v-if="message.link">
                                <a :href="prefix + message.link" >
                                    <p tabindex="0" class="focus:outline-none text-sm leading-none"><span class="text-indigo-700" v-html="message.message"/></p>
                                    <p tabindex="0" class="focus:outline-none text-xs leading-3 pt-1 text-gray-500" v-html="message.created_at"/>
                                </a>
                            </div>
                            <div class="pl-3" v-else>
                                <p tabindex="0" class="focus:outline-none text-sm leading-none"><span class="text-indigo-700" v-html="message.message"/></p>
                                <p tabindex="0" class="focus:outline-none text-xs leading-3 pt-1 text-gray-500" v-html="message.created_at"/>
                            </div>
                        </div>
                        <!-- Warning -->
                        <div class="w-full p-3 mt-4 bg-red-100 rounded flex items-center" v-if="message.type == 'warning'">
                            <div tabindex="0" aria-label="storage icon" role="img" class="focus:outline-none w-8 h-8 border rounded-full border-red-200 flex items-center flex-shrink-0 justify-center">
                                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/notification_1-svg6.svg"  alt="icon"/>
                            </div>
                            <div class="pl-3 w-full flex items-center justify-between">
                                <p tabindex="0" class="focus:outline-none text-sm leading-none text-red-700" v-html="message.message"/>
                                <p tabindex="0" class="focus:outline-none text-xs leading-3 pt-1 text-gray-500" v-html="message.created_at"/>
                            </div>

                        </div>
                        <!-- Success -->
                        <div class="w-full p-3 mt-8 bg-green-100 rounded flex items-center" v-if="message.type == 'success'">
                            <div tabindex="0" aria-label="success icon" role="img" class="focus:outline-none w-8 h-8 border rounded-full border-green-200 flex flex-shrink-0 items-center justify-center">
                                <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/notification_1-svg11.svg"  alt="icon"/>
                            </div>
                            <div class="pl-3 w-full">
                                <div class="flex items-center justify-between">
                                    <p tabindex="0" class="focus:outline-none text-sm leading-none text-green-700" v-html="message.message"/>
                                    <p tabindex="0" class="focus:outline-none focus:text-indigo-600 text-xs leading-3 underline cursor-pointer text-green-700" v-html="message.created_at"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div class="flex items-center justiyf-between">
                        <hr class="w-full">
                        <p tabindex="0" class="focus:outline-none text-sm flex flex-shrink-0 leading-normal px-3 py-16 text-gray-500">
                        <!-- Thats it for now -->{{$t('thats-it-for-now')}} :)</p>
                        <hr class="w-full">
                    </div>
                </div>
            </div>
        </div>
    <!-- ~~~~~~~~~~~~~~~~~~ -->
    </div>
    <div class="" v-if="$store.getters.showChangePasswordModel">
        <ChangePasswordModel/>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapActions } from 'vuex'
import { mapGetters } from 'vuex'
import {mapMutations} from 'vuex';
import {mapState} from 'vuex';
import { isEmpty } from 'lodash'
import localforage from 'localforage'
import ChangePasswordModel from '../ChangePasswordModel.vue'
export default {
  data: function() {
    return {
        isSubmenuFixed: false,
        showMenu:false,
        languageData: [],
        title:'',
        dropIt: false,
        notiHide: true,
        locale: localStorage.getItem("localforage/smartrecruit/locale") ? localStorage.getItem("localforage/smartrecruit/locale").replace(/['"]+/g, '') : 'en',
        prefix: process.env.BASE_URL,
        jiipaClientUrl: process.env.VUE_APP_JIIPA_URL,
        sekaiIchi: process.env.VUE_APP_SEKAI_ICHI_URL + 'product.php',
        bellNotifications: this.$store.getters['auth/user']['bellNotifications'],
        intervalId:'',
        setAuth : false,
    }
  },
  components: {
    ChangePasswordModel
  },
   
  computed: {
     ...mapState({
            language: 'language',
         }),
     ...mapGetters({ 
            user: 'auth/user',
            headerLinks: 'getMainHeaderLinks',
            // getLanguageList:'store/getLanguageList',
        }),
  },
    methods: {
        ...mapActions({
            fetchLanguageList: 'fetchLanguageList',
            markNotificationsAsSeen: 'auth/markNotificationsAsSeen',
            logout : 'auth/logout'
        }),
        toggleDropIt() {
            this.dropIt = !this.dropIt;
            if (this.dropIt) {
                this.disableScroll();
            } else {
                this.enableScroll();
            }
        },
        closeDropIt() {
            this.dropIt = false;
            this.enableScroll();
        },
        disableScroll() {
            document.body.style.overflow = 'hidden';
        },
        enableScroll() {
            document.body.style.overflow = '';
        },

        checkScroll() {
            const currentRoute = this.$route.name;
            const excludePaths = ['my-profile', 'edit-profile', 'change-password', 'Dashboard', 'agreement-scheduled-payment', 'paid-agreement', 'role-and-permission', 'role-add', 'role-edit', 'role-view', 'my-tasks-payment', 'my-tasks-jobs', 'my-tasks-projects', 'my-tasks-advertisements', 'my-tasks-job-fairs', 'my-tasks-refferals', 'my-tasks-agreements', 'my-tasks-scout-mail', 'candidates', 'job-view-list', 'JobCreateEdit', 'JobCreate', 'edit-job-by-id', 'view-job', 'job-seeker-resume', 'matching-candidates', 'jobseeker-resume', 'myjobfairs', 'addnewjobfair', 'editjobfair', 'viewjobfair', 'viewjobfairorganiser', 'viewjobfairUnsubscribed', 'jobseekerviewjobfair', 'block', 'view-employer-lists', 'scout-mail', 'view-scout-mail', 'edit-scout-mail', 'view-job-application', 'add-new-project', 'advertisements-list', 'add-advertisement', 'employer-view-job', 'user-list', 'view-employer', 'job-seeker-profile']; // Add more paths as needed
            if (!excludePaths.includes(currentRoute) && window.scrollY > 100) {
                this.isSubmenuFixed = true;
            } else {
                this.isSubmenuFixed = false;
            }
        },
    
        markAllSeen() {
            let vm = this
            this.markNotificationsAsSeen().then((resp) => {
                vm.bellNotificationHandler()
            })
        },
        bellNotificationHandler(flag) {    
            let notification = document.getElementById("notification");
            let checdiv = document.getElementById("chec-div");
            let flag3 = flag || false;
            if (!flag3) {
                notification.classList.add("translate-x-full");
                notification.classList.remove("translate-x-0");
                setTimeout(function () {
                    checdiv.classList.add("hidden");
                }, 500);
                flag3 = true;
            } else {
                setTimeout(function () {
                    notification.classList.remove("translate-x-full");
                    notification.classList.add("translate-x-0");
                }, 500);
                if (this.bellNotifications.count <= 0) {
                    return false
                }    
                checdiv.classList.remove("hidden");
                flag3 = false;
                this.notiHide = false
            }
        },
       
        languageList() {
            try{
                clearInterval(this.intervalId);
                this.fetchLanguageList().then((response) => {
                    this.languageData = response.data;
                });
            }catch(error){
                console.log('e',error);
            }
        },
   
    
    logout() {
        const payload = {
            'email' : localStorage.getItem('email'),
            'password' : localStorage.getItem('password'),
            'keepMeLoggedIn' : localStorage.getItem('keepMeLoggedIn')
        }
        this.$store.dispatch('auth/logout',payload).then(() => {
            this.$router.replace({ name: 'home' })
        })
    },
    languageHandler() {
        if (isEmpty(this.locale)){
            return false;
        }else{
            this.$store.commit('changeLanguageSlug', this.locale);
        }
        return false;
    },
    tokenCheck() {
        clearInterval(this.intervalId);
        localforage.getItem('authtoken').then((token) => {
            if(token) {
                this.setAuth = true;
            }else{
                this.setAuth = false;
            }
        })
    },
    getUserData(){
        let data = JSON.parse(localStorage.getItem('user'));
        if(data){
            this.$store.state.myprofile.firstname = data.firstname;
            this.$store.state.myprofile.lastname = data.lastname;
            this.$store.state.myprofile.designation = data.sso_designation;
            this.$store.state.myprofile.img = data.profile_image ? data.profile_image :"/images/vectorprofile.webp";
            this.$store.state.myprofile.role = data.sso_user_role;
            this.$store.state.myprofile.email = data.email;
            this.$store.state.myprofile.company_logo = '/images/smartrecruit.webp';
        } 
    }
  },
 
 
  mounted() {
    this.$store.state.myprofile;
    let vm = this
    this.$store.commit('HANDLE_MAIN_HEADER_BASED_ON_AUTH')
    // handling profile dropdown close on click anywhere
    // document.body.addEventListener('click', function(){vm.isDropped = false}, true);
    let currentLocale = 'en'
    if(localforage.getItem('localforage/smartrecruit/locale') == null){
        this.locale = currentLocale || 'en';
        localforage.setItem('locale', this.locale);
    }
    this.bellNotificationHandler(false);
    this.languageList();
    this.tokenCheck();
    this.getUserData();
     
    if(localStorage.getItem('localforage/smartrecruit/authtoken')){
        let token = localStorage.getItem('localforage/smartrecruit/authtoken').replace('"', '').replace('"', '');
        let loginUserData = JSON.parse(localStorage.getItem('loginUserData'));
        if((token != null || token != '') && (loginUserData == null || loginUserData == '')){
            this.logout();
        }
    }

        window.addEventListener('scroll', this.checkScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.checkScroll);
    }

};
</script>

<style>
.slide-enter-active {
  transition: 0.7s;
}
.slide-leave-active{
  transition: 0.6s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}
/* .menu_active:focus, .menu_active:active{
  background-color: #dc2626;
  color: #ffffff;
  padding: 0.5rem;
} */

.menu_active.router-link-exact-active{
  background-color: #dc2626;
  color: #ffffff;
  padding: 0.5rem;
  border-radius: 5px;
}
.menu_active_sign.router-link-exact-active{
  background-color: #dc2626;
  color: #ffffff;
  padding: 0.5rem;
  border-radius: 5px;

}


</style>
